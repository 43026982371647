import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext, StreamContext } from 'context/';
import { useGetSessions } from 'hooks/useServerQueries';
import StreamElement from '../StreamElement';
import StreamHeader from '../StreamHeader';
import { convertStreamTypeToValue } from 'utils/convertTypeToValue';
import { ISessionContent } from 'types';
import { SocketContext } from 'context/SocketContext';
import AudioLevelMeter from 'ui/components-library/AudioLevelMeter';
import { useGetCurrentOptions } from 'hooks/useServerQueries';
import { MediaStats } from '../MediaStats';
import HostedPlayer from '../HostedPlayer';
import '../../../../styles/globals.scss'
import {
    ChartBarIcon,
    ArrowsRightLeftIcon,
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
    ArrowPathIcon,
    ArrowRightCircleIcon
} from '@heroicons/react/24/outline';

export default function StreamWindow() {
  const { mutateAsync: GetSessions } = useGetSessions();
  const { sessionIdFromParams } = useContext(UserContext);
  const { 
    streamState, 
    audioElement, 
    setStreamState, 
    sessionData, 
    setSessionData, 
    dolbyStreamingConfig,
    webRTCPeer,
    setWebRTCPeer,
    useHostedPlayer,
    handlePlayerSwitch,
    isSwitching,
    setIsSwitching,
    setUseHostedPlayer
  } = useContext(StreamContext);
  //const [sessionData, setSessionData] = useState<ISessionContent>();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [showMainStream, setShowMainStream] = useState<any>();  
  const [streamStateLoc, setStreamStateLoc] = useState<string>('');
  const fullscreenObj = useRef<any>();
  const { socketData } = useContext(SocketContext);
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const {mutateAsync: GetCurrentOptions } = useGetCurrentOptions(sessionIdFromParams);
  const [showStats, setShowStats] = useState(false);
  // Add state for audio offset
  const [audioOffset, setAudioOffset] = useState<number>(0);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [reconnectProgress, setReconnectProgress] = useState(0);
  const [reconnectStage, setReconnectStage] = useState<'disconnecting' | 'connecting' | 'initializing' | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const [mountKey, setMountKey] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAudioOffset, setShowAudioOffset] = useState(false);
  const [isSourceSize, setIsSourceSize] = useState(false);

  const getListOfSessions = async () => {
    const data = await GetSessions();
    if (data) {
      data.content.filter(
        (item: ISessionContent) => item?.id === sessionIdFromParams && setSessionData(item),
      );
    }
  };

  const getCurrentOptions = async () => {
    const data = await GetCurrentOptions(sessionData?.id);
    if (data) {
      console.log("current stream state:", data)
      switch (data.streamControlStatus) {
        case "STARTED":
          setStreamState("Playing");
          break;
        case "STOPPED":
          setStreamState("Stopped");
          break;
        default:
          break;
      }
    
      //setShowMainStream(data);
    }
  };
  const updateStreamStateLoc = () => {
    const streamStateFunc = convertStreamTypeToValue(streamState);
    setStreamStateLoc(streamStateFunc);
  };

  const fullscreen = () => {
    setIsFullscreen(!isFullscreen);
    if (isFullscreen === true) fullscreenObj.current.requestFullscreen();
    else if(isFullscreen === false) if (document.fullscreenElement) {
      setTimeout(() => document.exitFullscreen(), 100);
    }
  };

  useEffect(() => {
    getListOfSessions();
    getCurrentOptions();
  }, []);

  useEffect(()=>{
    if (streamState) {
      updateStreamStateLoc();
    }
  }, [streamState]);

  useEffect (()=>{
    if (audioElement){
      audioElementRef.current = audioElement
    }
  }
  , [audioElement])

  // Add effect to listen for peer connection updates
  useEffect(() => {
    const videoElement = document.querySelector('video');
    if (videoElement && videoElement.srcObject) {
      const stream = videoElement.srcObject as MediaStream;
      const tracks = stream.getTracks();
      
      // Try to get the RTCPeerConnection from the track's transceiver
      if (tracks.length > 0) {
        const track = tracks[0];
        const sender = (track as any).sender;
        if (sender && sender.connection) {
          console.log('Found WebRTC peer connection through track sender');
          setWebRTCPeer(sender.connection);
        }
      }
    }
  }, [streamState, setWebRTCPeer]);

  // Debug logging for webRTCPeer
  useEffect(() => {
    console.log('WebRTC peer connection state:', webRTCPeer?.connectionState);
  }, [webRTCPeer]);

  useEffect(() => {console.log('reconnectingStatus', isReconnecting)} , [isReconnecting])

  // useEffect(() => {

  //   if (!socketData) {
  //       return
  //   }
  //     socketData.onmessage = function (event: any) {
  //       const eventData = JSON.parse(event.data);
  //       console.log("socket_event_data", eventData);
  //       // switch (eventData.type) {
  //       //   // case 'STREAM_CONTROL_SETTING_CHANGED': {
  //       //   //     if (eventData.streamControl.action === "STOP"){
  //       //   //         setShowMainStream(false);
  //       //   //         setStreamState('Paused');
  //       //   //         console.log('paused streamsß');
  //       //   //         break
  //       //   //     }
  //       //   //     else if (eventData.streamControl.action === "START"){
  //       //   //         setShowMainStream(true);
  //       //   //         setStreamState('Playing');
  //       //   //         console.log('started stream');
  //       //   //         break
  //       //   //     }
            
  //       //   //   break;
  //       //   // }
  //       //   case 'USER_LEFT_ROOM': {
            
  //       //     break;
  //       //   }
  //       //   case 'USER_DISCONNECTED_ROOM': {
    
  //       //     break;
  //       //   }
  //       // }
  //     }
    
  // },[socketData]);

  // Add handler for audio offset changes
  const handleAudioOffsetChange = (offset: number) => {
    console.log('Audio offset changed:', offset); // Debug log
    setAudioOffset(offset);
  };

  // Add effect to ensure audioOffset updates are reflected
  useEffect(() => {
    console.log('Current audio offset:', audioOffset); // Debug log
  }, [audioOffset]);

  const simulateDisconnection = async () => {
    setReconnectStage('disconnecting');
    setReconnectProgress(0);
    
    // Simulate connection loss
    for (let i = 100; i >= 0; i -= 20) {
      setReconnectProgress(i);
      await new Promise(resolve => setTimeout(resolve, 300));
    }
    
    // Complete disconnection pause
    await new Promise(resolve => setTimeout(resolve, 1000));
  };

  const simulateReconnection = async () => {
    setReconnectStage('connecting');
    setReconnectProgress(0);

    // Simulate connection restoration
    for (let i = 0; i <= 100; i += 20) {
      setReconnectProgress(i);
      await new Promise(resolve => setTimeout(resolve, 300));
    }
  };

  const initializeStream = async () => {
    setReconnectStage('initializing');
    setReconnectProgress(100);
    
    // Force remount of the player components
    setMountKey(prev => prev + 1);
    
    // Wait for stream initialization
    await new Promise(resolve => setTimeout(resolve, 2000));

    // Re-detect audio output device after reconnection
    if (videoRef.current) {
      try {
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const devices = await navigator.mediaDevices.enumerateDevices();
        const outputDevices = devices.filter(device => device.kind === 'audiooutput');
        const defaultDevice = outputDevices.find(device => device.deviceId === 'default');
        
        // Store the detected audio output information
        if (defaultDevice) {
          console.log('Detected audio output after reconnection:', defaultDevice.label);
        }

        // Close the audio context
        await audioContext.close();
      } catch (error) {
        console.error('Error detecting audio output after reconnection:', error);
      }
    }
  };

  const handleReconnect = async () => {
    
    setIsReconnecting(true);
    setStreamState('Reconnecting');
    setRetryCount(0);

    const attemptReconnection = async () => {
      try {
        // Phase 1: Disconnect and cleanup
        await simulateDisconnection();
        
        // Clean up existing WebRTC connection
        if (webRTCPeer) {
          webRTCPeer.close();
          setWebRTCPeer(null);
        }

        // Clean up video element
        if (videoRef.current) {
          const video = videoRef.current;
          if (video.srcObject) {
            const stream = video.srcObject as MediaStream;
            stream.getTracks().forEach(track => {
              track.stop();
            });
            video.srcObject = null;
          }
        }
        
        // Phase 2: Reconnect
        await simulateReconnection();
        
        // Phase 3: Initialize Stream
        await initializeStream();

        // Force MediaStats component to remount
        setShowStats(false);
        await new Promise(resolve => setTimeout(resolve, 100));
        setShowStats(true);
        
        setIsReconnecting(false);
        setReconnectStage(null);
        setReconnectProgress(0);
        setStreamState('Playing');
        
      } catch (error) {
        console.error('Reconnection error:', error);
        if (retryCount < MAX_RETRIES) {
          setRetryCount(prev => prev + 1);
          await new Promise(resolve => setTimeout(resolve, 1000));
          return attemptReconnection();
        } else {
          setIsReconnecting(false);
          setReconnectStage(null);
          setReconnectProgress(0);
          setStreamState('Error');
          alert('Failed to reconnect after multiple attempts. Please try again.');
        }
      }
    };

    await attemptReconnection();
  };

  const getReconnectMessage = () => {
    if (reconnectStage === 'disconnecting') {
      return 'Connection Lost';
    }
    if (reconnectStage === 'connecting') {
      return 'Restoring Connection';
    }
    if (reconnectStage === 'initializing') {
      return 'Initializing Stream';
    }
    return 'Reconnecting';
  };

  const handlePlayerSwitchLocal = async () => {
    if (isSwitching) return;
    
    try {
      setIsSwitching(true);
      console.log('Starting player switch from:', useHostedPlayer ? 'hosted' : 'custom');
      
      // First cleanup the current player
      await cleanupCurrentPlayer();
      
      // Force unmount by clearing the container
      setMountKey(prev => prev + 1);
      
      // Wait for DOM to update
      await new Promise(resolve => setTimeout(resolve, 200));
      
      // Switch player type using context
      await handlePlayerSwitch();
      
      // Reset stream state
      setStreamState('Stopped');
      
      console.log('Completed switch to:', !useHostedPlayer ? 'hosted' : 'custom');
    } catch (error) {
      console.error('Error during player switch:', error);
    } finally {
      setIsSwitching(false);
    }
  };

  const cleanupCurrentPlayer = async () => {
    try {
      console.log('Starting cleanup');
      setReconnectStage('disconnecting');
      setReconnectProgress(0);
      // Clean up WebRTC connections
      if (webRTCPeer) {
        webRTCPeer.close();
        setWebRTCPeer(null);
      }

      if (videoRef.current) {
        const video = videoRef.current;
        if (video.srcObject) {
          const stream = video.srcObject as MediaStream;
          stream.getTracks().forEach(track => {
            track.stop();
          });
          video.srcObject = null;
        }
      }

      // Reset stream state
      setStreamState('Stopped');
      
      // Wait for cleanup to complete
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log('Cleanup completed');
    } catch (error) {
      console.error('Error during player cleanup:', error);
    } finally {
      setReconnectStage(null);
      setReconnectProgress(100);
    }
  };

  const handleFitToSource = (dimensions: { width: number; height: number }) => {
    const videoContainer = document.querySelector('.relative.flex-grow');
    const videoElement = document.querySelector('video');
    
    if (!videoContainer || !videoElement) return;
    
    if (!isSourceSize) {
      // Store original styles before modifying
      videoElement.dataset.originalStyles = JSON.stringify({
        width: videoElement.style.width,
        height: videoElement.style.height,
        objectFit: videoElement.style.objectFit
      });
      
      // Apply source size
      videoElement.style.width = `${dimensions.width}px`;
      videoElement.style.height = `${dimensions.height}px`;
      videoElement.style.objectFit = 'none';
      videoElement.style.margin = 'auto';
    } else {
      // Restore original styles
      const originalStyles = videoElement.dataset.originalStyles 
        ? JSON.parse(videoElement.dataset.originalStyles)
        : { width: '100%', height: '100%', objectFit: 'contain' };
        
      videoElement.style.width = originalStyles.width;
      videoElement.style.height = originalStyles.height;
      videoElement.style.objectFit = originalStyles.objectFit;
      videoElement.style.margin = '';
    }
    
    setIsSourceSize(!isSourceSize);
  };

  return (
    <div ref={containerRef} className="relative w-full h-full bg-[#313139]">
      <div className="rounded-lg border-[#44444D] border h-full relative flex flex-col bg-[#313139]" ref={fullscreenObj}>
        {sessionData && (
          <>
            <div className={`title-Bg relative`}>
              <p className="text-[#8A8A96]">Video</p>
            </div>
            <StreamHeader
              streamName={sessionData.name}
              streamState={streamState}
            />
           
            <div key={mountKey} className="relative flex-grow">
              {useHostedPlayer ? (
                <div className="absolute inset-0">
                  <HostedPlayer
                    accountId={dolbyStreamingConfig.accountId}
                    streamName={dolbyStreamingConfig.streamName}
                    token={dolbyStreamingConfig.token}
                    autoplay={true}
                    muted={false}
                  />
                </div>
              ) : (
                <div className="absolute inset-0">
                  <StreamElement 
                    onAudioOffsetChange={handleAudioOffsetChange} 
                    showAudioOffset={showAudioOffset}
                    useHostedPlayer={useHostedPlayer}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-row px-4 py-4 bg-[#313139] rounded-b-lg items-center" style={{ justifyContent: 'flex-end', gap: '12px' }}>
              {/* Stats Toggle Button */}
              <div className="relative group flex items-center">
                <button
                  onClick={() => setShowStats(!showStats)}
                  className={`text-[#DDD8D8] hover:text-[#FB6520] transition-colors flex items-center justify-center h-5 w-5 ${showStats ? 'text-[#FB6520]' : 'text-[#DDD8D8]'}`}
                >
                  <svg className="h-[20px] w-[20px]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                </button>
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block w-max">
                  <div className="relative">
                    <div className="bg-[#313139] text-[#DDD8D8] text-xs px-2 py-1 rounded-[6px] border border-[#8A8A96] whitespace-nowrap">
                      Media Statistics
                    </div>
                    <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-[#313139] border-r border-b border-[#8A8A96]"></div>
                  </div>
                </div>
              </div>
              {/* Audio Offset Toggle Button */}
              <div className="relative group flex items-center">
                <button
                  onClick={() => setShowAudioOffset(!showAudioOffset)}
                  className={`text-[#DDD8D8] hover:text-[#FB6520] transition-colors flex items-center justify-center h-5 w-5 ${showAudioOffset ? 'text-[#FB6520]' : 'text-[#DDD8D8]'}`}
                >
                  <ArrowRightCircleIcon className="w-[20px] h-[20px]" />
                </button>
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block w-max">
                  <div className="relative">
                    <div className="bg-[#313139] text-[#DDD8D8] text-xs px-2 py-1 rounded-[6px] border border-[#8A8A96] whitespace-nowrap">
                      Audio Offset
                    </div>
                    <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-[#313139] border-r border-b border-[#8A8A96]"></div>
                  </div>
                </div>
              </div>
              {/* Player Switch Button */}
              {process.env.REACT_APP_API_URL == "https://api.stage.remotopro.io/v1/" && <div className="relative group flex items-center">
                <button
                  onClick={handlePlayerSwitchLocal}
                  disabled={isSwitching}
                  className={`text-[#DDD8D8] hover:text-[#FB6520] transition-colors flex items-center justify-center h-5 w-5 ${isSwitching ? 'text-[#FB6520]' : ''}`}
                >
                  {isSwitching ? (
                    <ArrowPathIcon className="w-[20px] h-[20px] animate-spin" />
                  ) : (
                    <ArrowsRightLeftIcon className="w-[20px] h-[20px]" />
                  )}
                </button>
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block w-max">
                  <div className="relative">
                    <div className="bg-[#313139] text-[#DDD8D8] text-xs px-2 py-1 rounded-[6px] border border-[#8A8A96] whitespace-nowrap">
                      Switch Player
                    </div>
                    <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-[#313139] border-r border-b border-[#8A8A96]"></div>
                  </div>
                </div>
              </div>}
              {/* Reconnect Button */}
              <div className="relative group flex items-center">
                <button
                  onClick={handleReconnect}
                  disabled={isReconnecting}
                  className={`text-[#DDD8D8] hover:text-[#FB6520] transition-colors flex items-center justify-center h-5 w-5 ${isReconnecting ? 'text-[#FB6520]' : ''}`}
                >
                  {isReconnecting ? (
                    <svg className="w-[20px] h-[20px] animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : (
                    <svg className="w-[20px] h-[20px]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                  )}
                </button>
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block w-max">
                  <div className="relative">
                    <div className="bg-[#313139] text-[#DDD8D8] text-xs px-2 py-1 rounded-[6px] border border-[#8A8A96] whitespace-nowrap">
                      Reload
                    </div>
                    <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-[#313139] border-r border-b border-[#8A8A96]"></div>
                  </div>
                </div>
              </div>
              {/* Fullscreen Button */}
              <div className="relative group flex items-center">
                <button className="flex items-center justify-center h-5 w-5 text-[#DDD8D8] hover:text-[#FB6520] transition-colors" onClick={fullscreen}>
                <img
                  src="/icons/fullScreen.svg"
                  alt="fullscreen"
                    className="w-[20px] h-[20px]"
                />
              </button>
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-2 hidden group-hover:block w-max">
                  <div className="relative">
                    <div className="bg-[#313139] text-[#DDD8D8] text-xs px-2 py-1 rounded-[6px] border border-[#8A8A96] whitespace-nowrap">
                      Fullscreen
                    </div>
                    <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 rotate-45 w-2 h-2 bg-[#313139] border-r border-b border-[#8A8A96]"></div>
                  </div>
                </div>
              </div>
              <img src="/icons/info.svg" alt="info" width={20} height={20} className='hidden'/>
            </div>

        {/* Stats Panel */}
        {showStats && (
  <div className="absolute top-[4.5rem] right-4 bg-[#313139] border border-[#44444D] rounded-lg shadow-lg z-[9999] ">
            <div className="p-4" >
              <button onClick={() => setShowStats(false)}> X </button>
              <MediaStats 
                peerConnection={webRTCPeer}
                isVisible={showStats}
                audioOffset={audioOffset}
                onFitToSource={handleFitToSource}
              />
            </div>
          </div>
        )}

            {/* Reconnecting Overlay */}
            {isReconnecting && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
                <div className="reconnect-panel reconnect-shadow rounded-lg p-6 text-center">
                  <div className="mb-4">
                    {reconnectStage === 'disconnecting' ? (
                      <svg className="h-10 w-10 text-red-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3" />
                      </svg>
                    ) : (
                      <svg className="animate-spin h-10 w-10 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    )}
                  </div>
                  <div className="text-lg font-semibold mb-2">
                    {getReconnectMessage()}
                  </div>
                  <div className="text-gray-600 reconnect-text">
                    {reconnectStage === 'disconnecting' ? 'Signal Strength' : 
                     reconnectStage === 'connecting' ? 'Buffering' :
                     'Preparing Video'}: {reconnectProgress}%
                    {retryCount > 0 && <div className="mt-1 text-sm text-yellow-600">Attempt {retryCount}/{MAX_RETRIES}</div>}
                  </div>
                  <div className="w-full reconnect-bar reconnect-bar-shadow-in rounded-full h-2.5 mt-2">
                    <div 
                      className={`h-2.5 rounded-full reconnect-bar-shadow transition-all duration-300 ${
                        reconnectStage === 'disconnecting' ? 'reconnect-bar-red' : 
                        reconnectStage === 'connecting' ? 'reconnect-bar-yellow' :
                        'reconnect-bar-green'
                      }`}
                      style={{ width: `${reconnectProgress}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
