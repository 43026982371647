import React, { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { UserContext } from 'context/';
import Providers from 'providers/index';
import {
  CheckSound,
  Login,
  Logout,
  Main,
  Stream,
  CheckYourEmail,
  PrivateRoutes,
} from 'pages';
import { useQuery } from 'hooks/useQuery';
import TestPlayer from './ui/components/Stream/HostedPlayer/TestPlayer';

function App() {
  const { sessionIdFromParams, setSessionIdFromParams } = useContext(UserContext);
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionId: string | null = query.get('session_id');
    const email: string | null = query.get('user_email');
    if (!sessionId) {
      const token = localStorage.getItem('token')
      const sessionIdLC = localStorage.getItem('sessionId')
      if (token && sessionIdLC) {
        navigate('/checksound');
        setSessionIdFromParams(sessionIdLC);
      } else {
        setTimeout( ()=> {navigate('/auth')}, 500);
      }
    }
    if (sessionId && email) {
      localStorage.setItem('sessionId', sessionId);
      localStorage.setItem('userEmail', email);
      (async () => {
        await setSessionIdFromParams(sessionId);

      })
      setTimeout( ()=> {navigate('/auth')}, 500);
    }
  }, [sessionIdFromParams]);

  return (
    <Providers>
      <Routes>
        <Route path="/auth" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/checkSound" element={<CheckSound />} />
          <Route path="/stream/:sessionIdFromParams" element={<Stream />} />
          <Route path="/stream-test" element={<TestPlayer />} />
          <Route path="/logout" element={<Logout />} />
        </Route>
        <Route path="/checkYourEmail" element={<CheckYourEmail />} />
      </Routes>
    </Providers>
  );
}

export default App;
