import React, { useEffect, useState, useRef } from 'react';
import { formatBytes } from 'utils/formatters';

interface Props {
  peerConnection: RTCPeerConnection | null;
  isVisible: boolean;
  audioOffset?: number;
  onFitToSource?: (dimensions: { width: number; height: number }) => void;
}

interface StabilityMetrics {
  stableTime: number;
  dropCount: number;
  recoveryCount: number;
  stabilizationProgress: string;
}

interface MediaStatsData {
  rtt: number;
  videoResolution: string;
  videoReceived: number;
  audioReceived: number;
  videoPacketLoss: number;
  audioPacketLoss: number;
  videoJitter: number;
  audioJitter: number;
  videoCodec: string;
  audioCodec: string;
  videoBitrate: number;
  audioBitrate: number;
  fps: number;
  syncOffset: number;
  audioChannels: number;
  audioConfig: string;
  audioSampleRate: number;
  displayedResolution: string;
  isUpscaling: boolean;
  scaleRatio: string;
  sourceAspectRatio: string;
  displayedAspectRatio: string;
  isAspectRatioMatch: boolean;
  isPiPActive: boolean;
  targetFPS: number;
  stableThreshold: number;
  isVideoStable: boolean;
  stabilityMetrics: StabilityMetrics;
  videoTimestamp: number | null;
  audioTimestamp: number | null;
  timestampDifference: number | null;
  videoTimestamps: {
    packetsReceived: number;
    packetTimestamp: number | null;
    rtpTimestamp: number | null;
    sequence: number | null;
    framesDecoded: number;
    totalInterFrameDelay: number | null;
    jitterBufferDelay: number | null;
    decodeTimestamp: number | null;
  };
  audioTimestamps: {
    packetsReceived: number;
    packetTimestamp: number | null;
    rtpTimestamp: number | null;
    sequence: number | null;
  };
  sync: {
    offset: number;
    history: number[];
    rtpDifference: number | null;
  };
  audioOutputDevice: string;
  audioOutputSampleRate: number;
}

interface BitrateHistory {
  timestamp: number;
  bytes: number;
}

const HISTORY_SIZE = 3; // Number of samples to keep for moving average
const DEFAULT_TARGET_FPS = 24; // Default target FPS
const STABLE_FPS_RATIO = 0.8;    // 80% of target FPS as the stability threshold

const initialStats: MediaStatsData = {
  rtt: 0,
  videoResolution: '',
  videoReceived: 0,
  audioReceived: 0,
  videoPacketLoss: 0,
  audioPacketLoss: 0,
  videoJitter: 0,
  audioJitter: 0,
  videoCodec: '',
  audioCodec: '',
  videoBitrate: 0,
  audioBitrate: 0,
  fps: 0,
  syncOffset: 0,
  audioChannels: 0,
  audioConfig: '',
  audioSampleRate: 0,
  displayedResolution: '',
  isUpscaling: false,
  scaleRatio: '',
  sourceAspectRatio: '',
  displayedAspectRatio: '',
  isAspectRatioMatch: false,
  isPiPActive: false,
  targetFPS: 0,
  stableThreshold: 0,
  isVideoStable: false,
  stabilityMetrics: {
    stableTime: 0,
    dropCount: 0,
    recoveryCount: 0,
    stabilizationProgress: 'Not started'
  },
  videoTimestamp: null,
  audioTimestamp: null,
  timestampDifference: null,
  videoTimestamps: {
    packetsReceived: 0,
    packetTimestamp: null,
    rtpTimestamp: null,
    sequence: null,
    framesDecoded: 0,
    totalInterFrameDelay: null,
    jitterBufferDelay: null,
    decodeTimestamp: null
  },
  audioTimestamps: {
    packetsReceived: 0,
    packetTimestamp: null,
    rtpTimestamp: null,
    sequence: null
  },
  sync: {
    offset: 0,
    history: [],
    rtpDifference: null
  },
  audioOutputDevice: '',
  audioOutputSampleRate: 0,
};

export const MediaStats: React.FC<Props> = ({
  peerConnection,
  isVisible,
  audioOffset = 0,
  onFitToSource
}) => {
  const [stats, setStats] = useState<MediaStatsData>(initialStats);
  const [error, setError] = useState<string>('');
  const [isPiPMode, setIsPiPMode] = useState(false);
  const isMounted = useRef(true);  // Add mounted ref
  const metricsCollectionRef = useRef<{ 
    startTime: number; 
    metrics: Array<MediaStatsData & { timestamp: number; manualAudioOffset: number }>; 
  }>({ startTime: Date.now(), metrics: [] });

  // Refs to maintain history & previous values
  const videoHistory = useRef<BitrateHistory[]>([]);
  const audioHistory = useRef<BitrateHistory[]>([]);
  const lastVideoBitrate = useRef<number>(0);
  const lastAudioBitrate = useRef<number>(0);
  const lastFps = useRef<number>(0);
  const lastFramesReceived = useRef<number>(0);
  const lastTimestamp = useRef<number>(0);

  // Refs for RTP normalization baselines
  const baselineVideoRtp = useRef<number | null>(null);
  const baselineAudioRtp = useRef<number | null>(null);

  // Ref to hold stability metrics between polling intervals
  const stabilityMetricsRef = useRef<StabilityMetrics>({
    stableTime: 0,
    dropCount: 0,
    recoveryCount: 0,
    stabilizationProgress: 'Not started'
  });

  // Add new refs for audio output persistence
  const audioOutputDeviceRef = useRef<string>('');
  const audioOutputSampleRateRef = useRef<number>(0);

  // Log audio offset updates
  useEffect(() => {
    if (isMounted.current) {
      console.log('MediaStats received audio offset:', audioOffset);
      // Update the latest metrics with new audio offset
      if (metricsCollectionRef.current.metrics.length > 0) {
        const lastMetric = metricsCollectionRef.current.metrics[metricsCollectionRef.current.metrics.length - 1];
        lastMetric.manualAudioOffset = audioOffset;
      }
    }
  }, [audioOffset]);

  const calculateBitrate = (history: BitrateHistory[], newBytes: number, now: number): number => {
    history.push({ timestamp: now, bytes: newBytes });
    while (history.length > HISTORY_SIZE) {
      history.shift();
    }
    if (history.length < 2) return 0;
    const oldest = history[0];
    const newest = history[history.length - 1];
    const duration = (newest.timestamp - oldest.timestamp) / 1000;
    if (duration <= 0) return 0;
    const bytesDiff = newest.bytes - oldest.bytes;
    const bits = bytesDiff * 8;
    const bps = bits / duration;
    return bps;
  };

  // Function to save metrics to file
  const saveMetricsToFile = () => {
    const metrics = metricsCollectionRef.current.metrics;
    const startTime = new Date(metricsCollectionRef.current.startTime).toISOString();
    const content = {
      startTime,
      duration: `${((Date.now() - metricsCollectionRef.current.startTime) / 1000).toFixed(2)} seconds`,
      sampleCount: metrics.length,
      metrics: metrics
    };

    const blob = new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `media-metrics-${startTime.replace(/[:.]/g, '-')}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Add button to UI
  const MetricsExportButton = () => (
    <button
      onClick={saveMetricsToFile}
      className="px-2 py-1 bg-[#FB6520] hover:bg-[#FB6520]/80 text-white rounded text-xs transition-colors"
    >
      Export Metrics
    </button>
  );

  // Collect metrics in the stats gathering loop
  useEffect(() => {
    // Set mounted to true when component mounts
    isMounted.current = true;

    let intervalId: NodeJS.Timeout;
    let isActive = true;

    const getStats = async () => {
      if (!peerConnection || !isVisible || !isMounted.current) {
        console.log('[MediaStats] Skipping stats collection:', {
          hasPeerConnection: !!peerConnection,
          isVisible,
          isMounted: isMounted.current
        });
        return;
      }

      // Additional check: only proceed if the connection state is 'connected'
      if (peerConnection.connectionState !== 'connected') {
        console.log('[MediaStats] Peer connection state is not connected:', peerConnection.connectionState);
        return;
      }

      try {
        const statsReport = await peerConnection.getStats();
        const newStats = { ...initialStats };
        const now = Date.now();

        // Debug log all reports
        statsReport.forEach(report => {
          console.log(`[MediaStats] Processing report:`, report);
        });

        // At the beginning of the getStats function, add local variables for RTP timestamps:
        let videoRtpTimestamp: number | null = null;
        let audioRtpTimestamp: number | null = null;

        // First pass to find codec info
        let videoTrackStats: any = null;
        let videoJitterDelay: number | null = null;
        let audioStatsReport: any = null;
        let audioCodecStats: any = null;
        let videoCodecStats: any = null;

        statsReport.forEach(report => {
          if (report.type === 'codec') {
            if (report.mimeType?.toLowerCase().includes('video')) {
              videoCodecStats = report;
              newStats.videoCodec = report.mimeType.split('/')[1];
            } else if (report.mimeType?.toLowerCase().includes('audio')) {
              audioCodecStats = report;
              newStats.audioCodec = report.mimeType.split('/')[1];
            }
          }
        });

        // Second pass for all other stats
        statsReport.forEach(report => {
          if (report.type === 'track' && report.kind === 'video') {
            videoTrackStats = report;
            if (report.frameWidth && report.frameHeight) {
              newStats.videoResolution = `${report.frameWidth}x${report.frameHeight}`;
            }
          }
          if (report.type === 'inbound-rtp') {
            const mediaType = report.mediaType || report.kind;

            if (mediaType === 'video') {
              // Video stats
              newStats.videoReceived = report.bytesReceived || 0;
              newStats.videoPacketLoss = report.packetsLost || 0;
              newStats.videoJitter = report.jitter ? Math.round(report.jitter * 1000) : 0;
              
              // Update video resolution if not already set
              if (!newStats.videoResolution && report.frameWidth && report.frameHeight) {
                newStats.videoResolution = `${report.frameWidth}x${report.frameHeight}`;
              }

              // Update video timing information and capture video RTP timestamp
              const rtpTs = report.lastPacketReceivedTimestamp ? now - report.lastPacketReceivedTimestamp : null;
              newStats.videoTimestamps = {
                packetsReceived: report.packetsReceived || 0,
                packetTimestamp: report.lastPacketReceivedTimestamp,
                rtpTimestamp: rtpTs,
                sequence: report.lastPacketReceivedTimestamp ? report.lastPacketReceivedTimestamp : null,
                framesDecoded: report.framesDecoded || 0,
                totalInterFrameDelay: report.totalInterFrameDelay || null,
                jitterBufferDelay: report.jitterBufferDelay || null,
                decodeTimestamp: report.lastFrameDecodedTimestamp || null
              };
              videoRtpTimestamp = rtpTs;

              // Calculate video bitrate
              const instantBitrate = calculateBitrate(videoHistory.current, report.bytesReceived || 0, now);
              const alpha = 0.85;
              newStats.videoBitrate = lastVideoBitrate.current === 0
                ? instantBitrate
                : (alpha * lastVideoBitrate.current + (1 - alpha) * instantBitrate);
              lastVideoBitrate.current = newStats.videoBitrate;

              // Calculate FPS
              if (report.framesDecoded && lastFramesReceived.current) {
                const framesDelta = report.framesDecoded - lastFramesReceived.current;
                const timeDelta = (now - lastTimestamp.current) / 1000;
                if (timeDelta > 0) {
                  const currentFps = framesDelta / timeDelta;
                  const alpha = 0.85;
                  newStats.fps = lastFps.current === 0
                    ? currentFps
                    : (alpha * lastFps.current + (1 - alpha) * currentFps);
                  lastFps.current = newStats.fps;
                }
              }
              lastFramesReceived.current = report.framesDecoded || 0;
              lastTimestamp.current = now;

            } else if (mediaType === 'audio') {
              // Audio stats
              newStats.audioReceived = report.bytesReceived || 0;
              newStats.audioPacketLoss = report.packetsLost || 0;
              newStats.audioJitter = report.jitter ? Math.round(report.jitter * 1000) : 0;
              
              // Update audio timing information and capture audio RTP timestamp
              const rtpTs = report.lastPacketReceivedTimestamp ? now - report.lastPacketReceivedTimestamp : null;
              newStats.audioTimestamps = {
                packetsReceived: report.packetsReceived || 0,
                packetTimestamp: report.lastPacketReceivedTimestamp,
                rtpTimestamp: rtpTs,
                sequence: report.lastPacketReceivedTimestamp ? report.lastPacketReceivedTimestamp : null
              };
              audioRtpTimestamp = rtpTs;

              // Calculate audio bitrate
              const instantBitrate = calculateBitrate(audioHistory.current, report.bytesReceived || 0, now);
              const alpha = 0.85;
              newStats.audioBitrate = lastAudioBitrate.current === 0
                ? instantBitrate
                : (alpha * lastAudioBitrate.current + (1 - alpha) * instantBitrate);
              lastAudioBitrate.current = newStats.audioBitrate;

              // Set audio channels and config with fallback
              if (report.channels) {
                newStats.audioChannels = report.channels;
              } else {
                // Fallback: try to get audio track settings from peerConnection
                if (peerConnection) {
                  const audioReceiver = peerConnection.getReceivers().find(r => r.track && r.track.kind === 'audio');
                  if (audioReceiver && audioReceiver.track) {
                    const settings = audioReceiver.track.getSettings();
                    newStats.audioChannels = settings.channelCount || 0;
                  }
                }
              }
              
              if (!newStats.audioSampleRate && peerConnection) {
                const audioReceiver = peerConnection.getReceivers().find(r => r.track && r.track.kind === 'audio');
                if (audioReceiver && audioReceiver.track) {
                  const settings = audioReceiver.track.getSettings();
                  newStats.audioSampleRate = settings.sampleRate || 0;
                }
              }

              // Set audio config based on channel count, if available
              if (newStats.audioChannels > 0) {
                newStats.audioConfig = newStats.audioChannels === 1 ? 'Mono' :
                                      newStats.audioChannels === 2 ? 'Stereo' :
                                      newStats.audioChannels === 6 ? '5.1 Surround' :
                                      newStats.audioChannels === 8 ? '7.1 Surround' :
                                      `${newStats.audioChannels} channels`;
              }
            }
          } else if (report.type === 'candidate-pair' && report.currentRoundTripTime) {
            newStats.rtt = Math.round(report.currentRoundTripTime * 1000);
          }
        });

        // Update stability metrics
        newStats.targetFPS = DEFAULT_TARGET_FPS;
        newStats.stableThreshold = newStats.targetFPS * STABLE_FPS_RATIO;
        if (newStats.fps >= newStats.stableThreshold) {
          newStats.isVideoStable = true;
          stabilityMetricsRef.current.stableTime += 1000;
          stabilityMetricsRef.current.stabilizationProgress = "Stable";
        } else {
          newStats.isVideoStable = false;
          stabilityMetricsRef.current.dropCount += 1;
          stabilityMetricsRef.current.stabilizationProgress = "Unstable";
        }
        newStats.stabilityMetrics = { ...stabilityMetricsRef.current };

        // Get displayed dimensions
        const videoElement = document.querySelector('video');
        if (videoElement) {
          const displayWidth = Math.round(videoElement.offsetWidth);
          const displayHeight = Math.round(videoElement.offsetHeight);
          if (displayWidth && displayHeight) {
            newStats.displayedResolution = `${displayWidth}x${displayHeight}`;
          }
        }

        // Calculate aspect ratios and scaling ratio if received resolution is available
        if (newStats.videoResolution) {
          const parts = newStats.videoResolution.split('x');
          if (parts.length === 2) {
            const receivedWidth = parseInt(parts[0]);
            const receivedHeight = parseInt(parts[1]);
            if (receivedWidth && receivedHeight) {
              newStats.sourceAspectRatio = (receivedWidth / receivedHeight).toFixed(2) + ':1';
            }
          }
        }
        if (newStats.displayedResolution) {
          const parts = newStats.displayedResolution.split('x');
          if (parts.length === 2) {
            const displayedWidth = parseInt(parts[0]);
            const displayedHeight = parseInt(parts[1]);
            if (displayedWidth && displayedHeight) {
              newStats.displayedAspectRatio = (displayedWidth / displayedHeight).toFixed(2) + ':1';
            }
          }
        }
        if (newStats.videoResolution && newStats.displayedResolution) {
          const rParts = newStats.videoResolution.split('x');
          const dParts = newStats.displayedResolution.split('x');
          if (rParts.length === 2 && dParts.length === 2) {
            const receivedWidth = parseInt(rParts[0]);
            const receivedHeight = parseInt(rParts[1]);
            const displayedWidth = parseInt(dParts[0]);
            const displayedHeight = parseInt(dParts[1]);
            if (receivedWidth && receivedHeight && displayedWidth && displayedHeight) {
              const scaleX = displayedWidth / receivedWidth;
              const scaleY = displayedHeight / receivedHeight;
              const scaleAvg = (scaleX + scaleY) / 2;
              newStats.scaleRatio = scaleAvg.toFixed(2) + 'x';
            }
          }
        }
        if (newStats.sourceAspectRatio && newStats.displayedAspectRatio) {
          const sourceAR = parseFloat(newStats.sourceAspectRatio.split(':')[0]);
          const displayedAR = parseFloat(newStats.displayedAspectRatio.split(':')[0]);
          newStats.isAspectRatioMatch = Math.abs(sourceAR - displayedAR) < 0.1;
        }

        // Preserve audio output info from refs so it isn't lost
        newStats.audioOutputDevice = audioOutputDeviceRef.current || newStats.audioOutputDevice;
        newStats.audioOutputSampleRate = audioOutputSampleRateRef.current || newStats.audioOutputSampleRate;

        // After the statsReport.forEach loop, assign the local RTP timestamps back to newStats (in case they were updated):
        newStats.videoTimestamps.rtpTimestamp = videoRtpTimestamp;
        newStats.audioTimestamps.rtpTimestamp = audioRtpTimestamp;

        // Then, calculate A/V sync metrics if both RTP timestamps are available
        if (videoRtpTimestamp !== null && audioRtpTimestamp !== null) {
          newStats.sync = {
            offset: videoRtpTimestamp - audioRtpTimestamp,
            history: [...(stats.sync?.history || [])],
            rtpDifference: videoRtpTimestamp - audioRtpTimestamp
          };
          newStats.sync.history.push(newStats.sync.offset);
          if (newStats.sync.history.length > 10) {
            newStats.sync.history.shift();
          }
          console.log('[MediaStats] Computed sync metrics:', newStats.sync);
        }

        const metricData = { timestamp: now, ...newStats, manualAudioOffset: audioOffset };
        metricsCollectionRef.current.metrics.push(metricData);

        if (isMounted.current) {
          setStats(newStats);
          setError('');
          console.log('[MediaStats] Stats updated successfully:', {
            fps: newStats.fps,
            videoBitrate: newStats.videoBitrate,
            audioBitrate: newStats.audioBitrate,
            videoTimestamps: newStats.videoTimestamps,
            audioTimestamps: newStats.audioTimestamps,
            sync: newStats.sync
          });
        }
      } catch (err: unknown) {
        if (isMounted.current) {
          const errorMessage = `Failed to get media stats: ${err instanceof Error ? err.message : String(err)}`;
          console.error('[MediaStats] Error:', errorMessage);
          setError(errorMessage);
        }
      }
    };

    if (isVisible && peerConnection) {
      getStats();
      intervalId = setInterval(getStats, 1000);
    }

    return () => {
      isActive = false;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [peerConnection, isVisible, audioOffset]);

  // Add audio context and output device detection
  useEffect(() => {
    const detectAudioOutput = async () => {
      try {
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const devices = await navigator.mediaDevices.enumerateDevices();
        const outputDevices = devices.filter(device => device.kind === 'audiooutput');
        const defaultDevice = outputDevices.find(device => device.deviceId === 'default') || outputDevices[0];
        
        if (isMounted.current) {
          setStats(prevStats => ({
            ...prevStats,
            audioOutputDevice: defaultDevice?.label || 'Default Output',
            audioOutputSampleRate: audioContext.sampleRate
          }));
          // Update refs to persist audio output info
          audioOutputDeviceRef.current = defaultDevice?.label || 'Default Output';
          audioOutputSampleRateRef.current = audioContext.sampleRate;
        }

        // Close the audio context since we only need it for detection
        await audioContext.close();
      } catch (error) {
        console.error('Error detecting audio output:', error);
        if (isMounted.current) {
          setStats(prevStats => ({
            ...prevStats,
            audioOutputDevice: 'Unknown',
            audioOutputSampleRate: 0
          }));
        }
      }
    };

    // Reset stats when peer connection changes
    if (peerConnection === null) {
      setStats(prevStats => ({
        ...initialStats,
        audioOutputDevice: prevStats.audioOutputDevice,
        audioOutputSampleRate: prevStats.audioOutputSampleRate
      }));
      return;
    }

    if (isVisible) {
      // Initial detection
      detectAudioOutput();

      // Set up connection state change listener
      const handleConnectionChange = () => {
        console.log('Connection state changed:', peerConnection.connectionState);
        if (peerConnection.connectionState === 'connected') {
          detectAudioOutput();
        }
      };

      peerConnection.addEventListener('connectionstatechange', handleConnectionChange);

      // Clean up
      return () => {
        peerConnection.removeEventListener('connectionstatechange', handleConnectionChange);
      };
    }
  }, [isVisible, peerConnection]);

  const toggleSourceSizePiP = async () => {
    const videoElement = document.querySelector('video');
    if (!videoElement) return;
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
        setIsPiPMode(false);
      } else {
        const [width, height] = stats.videoResolution.split('x').map(Number);
        if (!width || !height) {
          console.warn('Could not determine received canvas dimensions');
          return;
        }
        const pipWindow = await videoElement.requestPictureInPicture();
        setIsPiPMode(true);
        if (pipWindow && 'width' in pipWindow) {
          try {
            // @ts-ignore - Using experimental PictureInPictureWindow interface
            const pipWindowWithSize = pipWindow as { width: number; height: number };
            Object.defineProperties(pipWindowWithSize, {
              width: { value: width, configurable: true },
              height: { value: height, configurable: true }
            });
            pipWindow.addEventListener('resize', () => {
              Object.defineProperties(pipWindowWithSize, {
                width: { value: width, configurable: true },
                height: { value: height, configurable: true }
              });
            });
          } catch (e) {
            console.warn('Failed to set PiP window size:', e);
          }
        }
      }
    } catch (err) {
      console.error('PiP operation failed:', err);
    }
  };

  const handleFitToSource = () => {
    const videoElement = document.querySelector('video');
    if (!videoElement || !onFitToSource) return;
    const naturalWidth = videoElement.videoWidth;
    const naturalHeight = videoElement.videoHeight;
    if (!naturalWidth || !naturalHeight) return;
    onFitToSource({ width: naturalWidth, height: naturalHeight });
  };

  if (!isVisible) return null;

  const formatBitrate = (bps: number, isVideo: boolean) => {
    return isVideo
      ? `${(bps / 1000000).toFixed(2)} Mbps`
      : `${(bps / 1000).toFixed(1)} kbps`;
  };

  const formatSyncOffset = (offset: number): string => {
    const absOffset = Math.abs(offset);
    if (absOffset < 1) return 'In sync';
    const direction = offset > 0 ? 'ahead' : 'behind';
    return `Audio ${Math.round(absOffset)}ms ${direction}`;
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className="bg-black/80 text-white p-4 rounded-lg shadow-lg font-arial text-sm max-h-[85vh] overflow-y-auto">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-bold">Media Statistics</h3>
        <MetricsExportButton />
      </div>
      {error ? (
        <div className="text-red-500">{error}</div>
      ) : (
        <div className="space-y-1">
          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-2 pb-2">
              <span className="text-gray-400">RTT:</span> {stats.rtt}ms
            </div>
            <div className="col-span-2 pb-2">
              <span className="text-gray-400">A/V Drift:</span> {formatSyncOffset(stats.sync.offset)}
            </div>
            <div className="col-span-2 pb-2">
              <span className="text-gray-400">Audio Offset:</span> {audioOffset.toFixed(0)}ms
            </div>
            <div className="col-span-2 pb-2">
              <span className="text-gray-400">Final Audio Delay:</span> {(stats.sync.offset + audioOffset).toFixed(2)}ms
            </div>

            {/* Video Section */}
            <div className="col-span-2 border-t border-gray-700 py-2">
              <div className="font-semibold pb-1">Video</div>
              <div>
                <span className="text-gray-400">Received Canvas:</span> {stats.videoResolution}
                {stats.sourceAspectRatio && (
                  <span className="text-gray-400 ml-2">({stats.sourceAspectRatio})</span>
                )}
              </div>
              <div>
                <span className="text-gray-400">Displayed Canvas:</span> {stats.displayedResolution}
                <span className={`ml-2 ${stats.scaleRatio === '1:1' ? 'text-green-500' : stats.isUpscaling ? 'text-yellow-500' : 'text-blue-500'}`}>
                  ({stats.scaleRatio})
                </span>
                {stats.displayedAspectRatio && (
                  <span className={`ml-2 ${stats.isAspectRatioMatch ? 'text-green-500' : 'text-yellow-500'}`}>
                    {stats.displayedAspectRatio}
                    {!stats.isAspectRatioMatch && ' ≠'}
                  </span>
                )}
                {(stats.scaleRatio !== '1:1' || !stats.isAspectRatioMatch) && (
                  <div className="inline-flex ml-2">
                    <button
                      onClick={toggleSourceSizePiP}
                      className="px-2 py-0.5 bg-[#FB6520] hover:bg-[#FB6520]/80 text-white rounded text-xs transition-colors"
                      title="Open in Picture-in-Picture mode at source resolution"
                    >
                      {isPiPMode ? 'Exit PiP' : 'Source PiP'}
                    </button>
                  </div>
                )}
              </div>
              <div><span className="text-gray-400">FPS:</span> {stats.fps.toFixed(1)}</div>
              <div><span className="text-gray-400">Received:</span> {formatBytes(stats.videoReceived)}</div>
              <div><span className="text-gray-400">Bitrate:</span> {formatBitrate(stats.videoBitrate, true)}</div>
              <div><span className="text-gray-400">Packet Loss:</span> {stats.videoPacketLoss}</div>
              <div><span className="text-gray-400">Jitter:</span> {stats.videoJitter}ms</div>
              <div><span className="text-gray-400">Codec:</span> {stats.videoCodec}</div>
            </div>

            {/* Video Stability Metrics Section */}
            <div className="col-span-2 border-t border-gray-700 py-2">
              <div className="font-semibold pb-1">Video Stability Metrics</div>
              <div><span className="text-gray-400">Target FPS:</span> {stats.targetFPS}</div>
              <div><span className="text-gray-400">Stable Threshold:</span> {stats.stableThreshold}</div>
              <div><span className="text-gray-400">Video Stable:</span> {stats.isVideoStable ? "Stable" : "Unstable"}</div>
              <div className="text-xs">
                <div>Stable Time: {(stats.stabilityMetrics.stableTime / 1000).toFixed(1)}s</div>
                <div>Drops: {stats.stabilityMetrics.dropCount}</div>
                <div>Recoveries: {stats.stabilityMetrics.recoveryCount}</div>
                <div>Progress: {stats.stabilityMetrics.stabilizationProgress}</div>
              </div>
            </div>

            {/* Audio Section */}
            <div className="col-span-2 border-t border-gray-700 py-2">
              <div className="font-semibold pb-1">Audio</div>
              <div><span className="text-gray-400">Received:</span> {formatBytes(stats.audioReceived)}</div>
              <div><span className="text-gray-400">Bitrate:</span> {formatBitrate(stats.audioBitrate, false)}</div>
              <div><span className="text-gray-400">Packet Loss:</span> {stats.audioPacketLoss}</div>
              <div><span className="text-gray-400">Jitter:</span> {stats.audioJitter}ms</div>
              <div><span className="text-gray-400">Codec:</span> {stats.audioCodec}</div>
              <div>
                <span className="text-gray-400">Channels:</span> {stats.audioChannels > 0 ? `${stats.audioChannels} (${stats.audioConfig})` : 'Unknown'}
                {stats.audioSampleRate > 0 && <span className="ml-1">@ {(stats.audioSampleRate / 1000).toFixed(0)}kHz</span>}
              </div>
              <div>
                <span className="text-gray-400">Audio Out:</span>{' '}
                {stats.audioOutputDevice}
                {stats.audioOutputSampleRate > 0 && <span className="ml-1">@ {(stats.audioOutputSampleRate / 1000).toFixed(0)}kHz</span>}
              </div>
            </div>

            <div className="col-span-2 border-t border-gray-700 py-2">
              <div className="font-semibold pb-1">Timing Information</div>
              <div className="text-xs space-y-1">
                <div>
                  <span className="text-gray-400">Video Packets:</span>{' '}
                  {stats.videoTimestamps.packetsReceived} received
                  {stats.videoTimestamps.packetTimestamp &&
                    ` (Last: ${new Date(stats.videoTimestamps.packetTimestamp).toISOString()})`}
                </div>
                <div>
                  <span className="text-gray-400">Video RTP:</span>{' '}
                  {stats.videoTimestamps.rtpTimestamp !== null
                    ? `${stats.videoTimestamps.rtpTimestamp.toFixed(2)}ms`
                    : 'N/A'}
                </div>
                <div>
                  <span className="text-gray-400">Video Frames:</span>{' '}
                  {stats.videoTimestamps.framesDecoded} decoded
                  {stats.videoTimestamps.decodeTimestamp &&
                    ` (Last: ${new Date(stats.videoTimestamps.decodeTimestamp).toISOString()})`}
                </div>
                <div>
                  <span className="text-gray-400">Inter-Frame Delay:</span>{' '}
                  {stats.videoTimestamps.totalInterFrameDelay !== null && !isNaN(stats.videoTimestamps.totalInterFrameDelay)
                    ? `${(stats.videoTimestamps.totalInterFrameDelay * 1000).toFixed(2)}ms`
                    : 'N/A'}
                </div>
                <div>
                  <span className="text-gray-400">Jitter Buffer Delay:</span>{' '}
                  {stats.videoTimestamps.jitterBufferDelay !== null && !isNaN(stats.videoTimestamps.jitterBufferDelay)
                    ? `${stats.videoTimestamps.jitterBufferDelay}ms`
                    : 'N/A'}
                </div>
                <div>
                  <span className="text-gray-400">Audio Packets:</span>{' '}
                  {stats.audioTimestamps.packetsReceived} received
                  {stats.audioTimestamps.packetTimestamp &&
                    ` (Last: ${new Date(stats.audioTimestamps.packetTimestamp).toISOString()})`}
                </div>
                <div>
                  <span className="text-gray-400">Audio RTP:</span>{' '}
                  {stats.audioTimestamps.rtpTimestamp !== null
                    ? `${stats.audioTimestamps.rtpTimestamp.toFixed(2)}ms`
                    : 'N/A'}
                </div>
                <div>
                  <span className="text-gray-400">A/V RTP Difference:</span>{' '}
                  {stats.sync?.rtpDifference !== null && stats.sync.rtpDifference !== undefined ? `${stats.sync.rtpDifference.toFixed(2)}ms` : 'N/A'}
                </div>
                <div>
                  <span className="text-gray-400">Sync Offset:</span>{' '}
                  {stats.sync?.offset !== null && stats.sync.offset !== undefined ? `${stats.sync.offset.toFixed(2)}ms` : 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
