import React from 'react';

interface HostedPlayerProps {
    accountId: string;
    streamName: string;
    token: string;
    autoplay?: boolean;
    muted?: boolean;
}

const HostedPlayer: React.FC<HostedPlayerProps> = ({
    accountId,
    streamName,
    token,
    autoplay = true,
    muted = false
}) => {
    // Construct the player URL with all necessary parameters
    const baseUrl = 'https://viewer.millicast.com';
    const params = new URLSearchParams({
        streamId: `${accountId}/${streamName}`,
        autoplay: autoplay.toString(),
        muted: muted.toString(),
        disableStats: 'true' // Disable built-in stats to prevent conflicts
    });

    // Add token for secure stream
    if (token) {
        params.append('token', token);
    }

    const playerUrl = `${baseUrl}?${params.toString()}`;

    return (
        <div className="relative w-full h-full" data-player-container="hosted">
            <iframe
                src={playerUrl}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{
                    border: 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                }}
                title="Dolby.io Stream Player"
            />
        </div>
    );
};

export default HostedPlayer; 