import React from 'react';
import HostedPlayer from './index';

const TestPlayer: React.FC = () => {
    // These values should come from your configuration
    const accountId = process.env.REACT_APP_MILLICAST_ACCOUNT_ID || '';
    const streamName = process.env.REACT_APP_MILLICAST_STREAM_NAME || '';
    const token = process.env.REACT_APP_MILLICAST_VIEWER_TOKEN; // Optional for secure streams

    return (
        <div style={{ width: '100vw', height: '100vh', padding: '20px' }}>
            <h1>Hosted Player Test</h1>
            <div style={{ width: '800px', height: '450px', margin: '20px auto' }}>
                <HostedPlayer
                    accountId={accountId}
                    streamName={streamName}
                    token={token}
                    autoplay={true}
                    muted={true}
                />
            </div>
        </div>
    );
};

export default TestPlayer; 