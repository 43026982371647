import React from 'react';

interface IStreamState {
  streamName: string;
  streamState: string;
}

export default function StreamHeader({
  streamName,
  streamState,
}: Readonly<IStreamState>) {

  function getBadgeColor(streamState: string) {
    switch (streamState) {
      case 'Playing':
        return '#27AE60';
      case 'Paused':
        return '#F44336';
      case 'Error':
        return '#FB6520'; // Orange color for waiting state
      default:
        return 'grey';
    }
  }

  return (
    <div className="flex flex-row justify-between items-center px-4 py-4 bg-[#313139] border-t-[#44444D] border-t">
      <p className="text-[#FB6520] capitalize">{streamName}</p>
      {streamState && <div className="px-2 py-1 rounded-md" style={{ backgroundColor: getBadgeColor(streamState) }}>
        <p>{streamState === 'Error' ? 'Waiting for Stream' : streamState}</p>
      </div>}
    </div>
  );
}
