import { createContext } from 'react';
import { IStreamContext } from 'types/context';

// Add debug logging for state changes
const createLoggingProxy = (obj: any, name: string) => {
  return new Proxy(obj, {
    set(target, property, value) {
      console.log(`[StreamContext] ${name}.${String(property)} changed:`, value);
      target[property] = value;
      return true;
    }
  });
};

const streamContextDefaults: IStreamContext = createLoggingProxy({
  setStreamId: () => {
    console.log('[StreamContext] setStreamId called with null');
    return null;
  },
  streamId: null,
  setStreamName: () => {
    console.log('[StreamContext] setStreamName called with null');
    return null;
  },
  streamName: null,
  setStreamState: (state: string) => {
    console.log('[StreamContext] Stream state changing to:', state);
    return null;
  },
  streamState: null,
  setZegoCloudCred: () => {
    console.log('[StreamContext] setZegoCloudCred called');
    return null;
  },
  zegoCloudCred: null,
  setDolbyStreamingConfig: (config: any) => {
    console.log('[StreamContext] Dolby streaming config updated:', config);
    return null;
  },
  dolbyStreamingConfig: null,
  setOrganizer: () => null,
  organizer: null,
  setRoomId: () => null,
  roomId: null,
  setSessionData: () => null,
  sessionData: null,
  setRoomData: () => null,
  roomData: null,
  setMicrophoneDevices: () => null,
  microphoneDevices: null,
  setSpeakerDevices: () => null,
  speakerDevices: null,
  setCameraDevices: () => null,
  cameraDevices: null,
  setSpeakerDeviceSelected: () => null,
  speakerDeviceSelected: null,
  setMicrophoneDeviceSelected: () => null,
  cameraDeviceSelected: null,
  setCameraDeviceSelected: () => null,
  microphoneDeviceSelected: null,
  setMuteMicrophone: () => false,
  muteMicrophone: false,
  setMuteCamera: () => false,
  muteCamera: false,
  setMuteCallOutput: () => false,
  muteCallOutput: false,
  setCallOutputLevel: () => null,
  callOutputLevel: 0, 
  setCallOutputGain: () => null,
  callOutputGain: .5, 
  setMuteStreamOutput: () => false,
  muteStreamOutput: false,
  setStreamOutputLevel: () => .5,
  streamOutputLevel: .5, 
  setStreamOutputGain: () => .5,
  streamOutputGain: .5, 
  setAudioElement: () => null,
  audioElement: null,
  setLocalStream: () => null,
  localStream: null,
  setStreamIDs: () => null,
  streamIDs: null,
  setLocalVideoRef: () => null,
  localVideoRef: null,
  setRemoteStreams: () => null,
  remoteStreams: null,
  setRemoteCameraStatus: () => null,
  remoteCameraStatus: null,
  setRemoteMicStatus: () => null,
  remoteMicStatus: null,
  setRemoteSpeakerStatus: () => null,
  remoteSpeakerStatus: null,
}, 'streamContextDefaults');

console.log('[StreamContext] Initializing with defaults:', streamContextDefaults);

export const StreamContext = createContext<IStreamContext>(
  streamContextDefaults,
);
