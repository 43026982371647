import React, { useState, useEffect, useContext, useRef } from 'react';
import HostedPlayer from '../HostedPlayer';
import StreamWindow from '../StreamWindow';
import { StreamContext } from 'context';

interface PlayerSelectorProps {
    dolbyConfig: any;
    sessionData: any;
}

const PlayerSelector: React.FC<PlayerSelectorProps> = ({ dolbyConfig, sessionData }) => {
    const { 
        setStreamState, 
        streamState,
        useHostedPlayer,
        setUseHostedPlayer,
        isSwitching,
        setIsSwitching,
        handlePlayerSwitch
    } = useContext(StreamContext);
    const [key, setKey] = useState(0);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [reconnectProgress, setReconnectProgress] = useState(0);
    const [reconnectStage, setReconnectStage] = useState<'disconnecting' | 'connecting' | 'initializing' | null>(null);
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 3;
    const currentPlayerRef = useRef<'custom' | 'hosted' | null>(null);
    const [mountKey, setMountKey] = useState(0);

    // Update current player type reference
    useEffect(() => {
        currentPlayerRef.current = useHostedPlayer ? 'hosted' : 'custom';
        console.log('Current player type updated to:', currentPlayerRef.current);
    }, [useHostedPlayer]);

    const cleanupCurrentPlayer = async () => {
        try {
            console.log('Starting cleanup, current player:', currentPlayerRef.current);
            setReconnectStage('disconnecting');
            setReconnectProgress(0);

            // Clean up iframes if switching from hosted player
            if (currentPlayerRef.current === 'hosted') {
                try {
                    console.log('Cleaning up hosted player...');
                    const iframes = document.querySelectorAll('iframe');
                    iframes.forEach(iframe => {
                        iframe.src = 'about:blank';
                        iframe.remove();
                    });
                } catch (error) {
                    console.warn('Non-critical error during iframe cleanup:', error);
                }
            }

            // Only clean up video elements that are part of the current player
            const playerContainer = document.querySelector(`[data-player-container="${currentPlayerRef.current}"]`);
            if (playerContainer) {
                const videoElements = playerContainer.querySelectorAll('video');
                videoElements.forEach(video => {
                    if (video.srcObject) {
                        const stream = video.srcObject as MediaStream;
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                        video.srcObject = null;
                    }
                    video.remove();
                });

                // Clean up audio elements within the current player container
                const audioElements = playerContainer.querySelectorAll('audio');
                audioElements.forEach(audio => {
                    if (audio.srcObject) {
                        const stream = audio.srcObject as MediaStream;
                        stream.getTracks().forEach(track => {
                            track.stop();
                        });
                        audio.srcObject = null;
                    }
                    audio.remove();
                });
            }

            // Reset stream state
            setStreamState('Stopped');
            
            // Wait for cleanup to complete
            await new Promise(resolve => setTimeout(resolve, 1000));
            console.log('Cleanup completed');
        } catch (error) {
            console.error('Error during player cleanup:', error);
        } finally {
            setReconnectStage(null);
            setReconnectProgress(100);
        }
    };

    const handlePlayerSwitchLocal = async () => {
        if (isSwitching) return;
        
        try {
            setIsSwitching(true);
            console.log('Starting player switch from:', useHostedPlayer ? 'hosted' : 'custom');
            
            // First cleanup the current player
            await cleanupCurrentPlayer();
            
            // Force unmount by clearing the container
            setMountKey(prev => prev + 1);
            
            // Wait for DOM to update
            await new Promise(resolve => setTimeout(resolve, 200));
            
            // Switch player type using context
            await handlePlayerSwitch();
            
            // Reset stream state
            setStreamState('Stopped');
            
            console.log('Completed switch to:', !useHostedPlayer ? 'hosted' : 'custom');
        } catch (error) {
            console.error('Error during player switch:', error);
        } finally {
            setIsSwitching(false);
        }
    };

    // Return early if no config
    if (!dolbyConfig || !sessionData) {
        return <div>Loading...</div>;
    }

    const simulateDisconnection = async () => {
        setReconnectStage('disconnecting');
        setReconnectProgress(0);
        
        // Simulate connection loss
        for (let i = 100; i >= 0; i -= 20) {
            setReconnectProgress(i);
            await new Promise(resolve => setTimeout(resolve, 300));
        }
        
        // Complete disconnection pause
        await new Promise(resolve => setTimeout(resolve, 1000));
    };

    const simulateReconnection = async () => {
        setReconnectStage('connecting');
        setReconnectProgress(0);

        // Simulate connection restoration
        for (let i = 0; i <= 100; i += 20) {
            setReconnectProgress(i);
            await new Promise(resolve => setTimeout(resolve, 300));
        }
    };

    const initializeStream = async () => {
        setReconnectStage('initializing');
        setReconnectProgress(100);
        
        // Force remount of the player components
        setKey(prevKey => prevKey + 1);
        
        // Wait for stream initialization
        await new Promise(resolve => setTimeout(resolve, 2000));
    };

    const handleReconnect = async () => {
        setIsReconnecting(true);
        setStreamState('Reconnecting');
        setRetryCount(0);

        const attemptReconnection = async () => {
            try {
                // Phase 1: Disconnect
                await simulateDisconnection();
                
                // Phase 2: Reconnect
                await simulateReconnection();
                
                // Phase 3: Initialize Stream
                await initializeStream();
                
                setIsReconnecting(false);
                setReconnectStage(null);
                setReconnectProgress(0);
                setStreamState('Playing');
                
            } catch (error) {
                console.error('Reconnection error:', error);
                if (retryCount < MAX_RETRIES) {
                    setRetryCount(prev => prev + 1);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    return attemptReconnection();
                } else {
                    setIsReconnecting(false);
                    setReconnectStage(null);
                    setReconnectProgress(0);
                    setStreamState('Error');
                    alert('Failed to reconnect after multiple attempts. Please try again.');
                }
            }
        };

        await attemptReconnection();
    };

    const getReconnectMessage = () => {
        if (reconnectStage === 'disconnecting') {
            return 'Connection Lost';
        }
        if (reconnectStage === 'connecting') {
            return 'Restoring Connection';
        }
        if (reconnectStage === 'initializing') {
            return 'Initializing Stream';
        }
        return 'Reconnect';
    };

    return (
        <div className="relative w-full h-full">
            {/* Control Buttons */}
            <div className="absolute top-4 right-4 z-10 flex gap-2">
                {useHostedPlayer && (
                    <>
                        <button
                            onClick={handlePlayerSwitchLocal}
                            disabled={isSwitching}
                            className={`${
                                isSwitching 
                                    ? 'bg-gray-500 cursor-not-allowed' 
                                    : 'bg-blue-500 hover:bg-blue-700'
                            } text-white font-bold py-2 px-4 rounded flex items-center gap-2`}
                        >
                            {isSwitching ? (
                                <>
                                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Switching...
                                </>
                            ) : (
                                <>
                                    <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                                    </svg>
                                    Switch to Custom Player
                                </>
                            )}
                        </button>
                        <button
                            onClick={handleReconnect}
                            disabled={isReconnecting}
                            className={`${
                                isReconnecting 
                                    ? 'bg-gray-500 cursor-not-allowed' 
                                    : 'bg-green-500 hover:bg-green-700'
                            } text-white font-bold py-2 px-4 rounded flex items-center gap-2`}
                        >
                            {isReconnecting ? (
                                <>
                                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {getReconnectMessage()} {retryCount > 0 ? `(Attempt ${retryCount}/${MAX_RETRIES})` : `(${reconnectProgress}%)`}
                                </>
                            ) : (
                                <>
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                    </svg>
                                    Reconnect
                                </>
                            )}
                        </button>
                    </>
                )}
            </div>

            {/* Buffering Overlay */}
            {isReconnecting && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
                    <div className="bg-white rounded-lg p-6 text-center">
                        <div className="mb-4">
                            {reconnectStage === 'disconnecting' ? (
                                <svg className="h-10 w-10 text-red-500 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3" />
                                </svg>
                            ) : (
                                <svg className="animate-spin h-10 w-10 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            )}
                        </div>
                        <div className="text-lg font-semibold mb-2">
                            {reconnectStage === 'disconnecting' ? 'Connection Lost' : 
                             reconnectStage === 'connecting' ? 'Restoring Connection' :
                             'Initializing Stream'}
                        </div>
                        <div className="text-gray-600">
                            {reconnectStage === 'disconnecting' ? 'Signal Strength' : 
                             reconnectStage === 'connecting' ? 'Buffering' :
                             'Preparing Video'}: {reconnectProgress}%
                            {retryCount > 0 && <div className="mt-1 text-sm text-yellow-600">Attempt {retryCount}/{MAX_RETRIES}</div>}
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                            <div 
                                className={`h-2.5 rounded-full transition-all duration-300 ${
                                    reconnectStage === 'disconnecting' ? 'bg-red-500' : 
                                    reconnectStage === 'connecting' ? 'bg-blue-500' :
                                    'bg-green-500'
                                }`}
                                style={{ width: `${reconnectProgress}%` }}
                            ></div>
                        </div>
                    </div>
                </div>
            )}

            {/* Player Container */}
            <div key={mountKey} className="w-full h-full">
                {!isSwitching && (
                    useHostedPlayer ? (
                        <HostedPlayer
                            accountId={dolbyConfig.accountId}
                            streamName={dolbyConfig.streamName}
                            token={dolbyConfig.token}
                            autoplay={true}
                            muted={false}
                        />
                    ) : (
                        <StreamWindow />
                    )
                )}
            </div>
        </div>
    );
};

export default PlayerSelector;