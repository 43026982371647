import { Director, Media, View } from "@millicast/sdk";
import { MutableRefObject } from "react";

interface MilicastResult {
  view: View;
  peer: RTCPeerConnection | null;
}

export const millicast = async (
  dolbyConfig: any,
  videoEl: MutableRefObject<any>,
  setStreamState: (state: string) => void
): Promise<MilicastResult> => {
  // Track stream state locally
  let currentStreamState = 'Initializing';

  // Destructure configuration values for clarity.
  const { streamName, accountId, token } = dolbyConfig || {};
  console.log("[Millicast] Initializing with config:", {
    hasStreamName: !!streamName,
    hasAccountId: !!accountId,
    hasToken: !!token
  });

  // Token generator callback
  const tokenGenerator = () => {
    console.log("[Millicast] Generating subscriber token");
    return Director.getSubscriber({
      streamName,
      streamAccountId: accountId,
      subscriberToken: token,
    });
  };

  // Create Millicast view instance.
  const view = new View(streamName, tokenGenerator);
  console.log("[Millicast] View instance created");

  // Helper for updating stream state.
  const handleStreamState = (state: string) => {
    console.log("[Millicast] Stream state transition:", {
      from: currentStreamState,
      to: state,
      currentSourceId,
      hasWebRTCPeer: !!view.webRTCPeer,
      peerState: view.webRTCPeer?.peer?.connectionState
    });
    currentStreamState = state;
    setStreamState(state);
  };

  // Single, unified track event listener.
  view.on("track", (event) => {
    const stream = event.streams[0];
    console.log("[Millicast] Track event received:", {
      audioTracks: stream.getAudioTracks().length,
      videoTracks: stream.getVideoTracks().length,
      trackKind: event.track.kind,
      trackEnabled: event.track.enabled,
      trackReadyState: event.track.readyState
    });

    // Ensure we have both audio and video tracks before proceeding
    if (stream.getAudioTracks().length === 0 || stream.getVideoTracks().length === 0) {
      console.warn("[Millicast] Missing audio or video tracks, waiting for complete stream");
      return;
    }

    addStreamToYourVideoTag(stream);
    
    // Only set to Playing if we have a valid connection and source
    if (view.webRTCPeer?.peer?.connectionState === 'connected' && currentSourceId) {
      console.log("[Millicast] All conditions met for Playing state:", {
        connectionState: view.webRTCPeer.peer.connectionState,
        sourceId: currentSourceId,
        audioTracks: stream.getAudioTracks().length,
        videoTracks: stream.getVideoTracks().length
      });
      handleStreamState("Playing");
    } else {
      console.log("[Millicast] Waiting for conditions to play:", {
        connectionState: view.webRTCPeer?.peer?.connectionState,
        hasSourceId: !!currentSourceId
      });
    }
  });

  // FPS monitoring and state variables.
  let lastFpsZeroTime: number | null = null;
  const FPS_ZERO_THRESHOLD = 3000; // 3 seconds threshold
  let fpsHistory: number[] = [];
  const FPS_HISTORY_SIZE = 5;
  let wasStreamPaused = false;
  let lastFrames = 0;
  let lastTime = Date.now();
  let isReconnecting = false;
  let currentSourceId: string | null = null;

  // Helper to clean up video tracks.
  const cleanupTracks = async () => {
    console.log("Cleaning up tracks");
    if (videoEl.current?.srcObject) {
      const stream = videoEl.current.srcObject as MediaStream;
      stream.getTracks().forEach((track) => {
        console.log(`Stopping track: ${track.kind}`);
        track.enabled = false;
        track.stop();
      });
      videoEl.current.srcObject = null;
    }

    // Also cleanup any peer connection resources
    if (view.webRTCPeer?.peer) {
      view.webRTCPeer.peer.getTransceivers().forEach((transceiver) => {
        if (typeof transceiver.stop === "function") {
          transceiver.stop();
        }
      });
    }
  };

  // Helper to reset state
  const resetState = () => {
    console.log("Resetting state variables");
    currentSourceId = null;
    lastFpsZeroTime = null;
    wasStreamPaused = false;
    fpsHistory = [];
    lastFrames = 0;
    lastTime = Date.now();
    isReconnecting = false;
  };

  // Stop the stream and cleanup resources.
  const handleStreamStop = async () => {
    try {
      console.log("Stopping stream and cleaning up resources");
      await cleanupTracks();
      await view.stop();
      resetState();
    } catch (error) {
      console.error("Error during stream stop:", error);
    }
  };

  // Handle broadcast events.
  view.on("broadcastEvent", async (event) => {
    const { name, data } = event;
    console.log("[Millicast] Broadcast event received:", {
      name,
      data,
      currentState: currentStreamState,
      isReconnecting,
      peerState: view.webRTCPeer?.peer?.connectionState
    });

    switch (name) {
      case "active":
        if (data.sourceId != null) {
          currentSourceId = data.sourceId;
          console.log("[Millicast] Stream active:", {
            sourceId: data.sourceId,
            isReconnecting,
            peerState: view.webRTCPeer?.peer?.connectionState,
            currentState: currentStreamState
          });
          
          // Always reinitialize on active event if coming from stopped state
          if (currentStreamState === 'Stopped' || !view.webRTCPeer?.peer || view.webRTCPeer.peer.connectionState !== 'connected') {
            console.log("[Millicast] Reinitializing connection from state:", currentStreamState);
            try {
              // First ensure clean state
              await handleStreamStop();
              // Then attempt new connection
              await view.connect(options);
              console.log("[Millicast] Reinitialization successful");
              
              // Wait for connection to establish
              if (view.webRTCPeer?.peer) {
                view.webRTCPeer.peer.addEventListener('connectionstatechange', function onConnect() {
                  if (view.webRTCPeer?.peer?.connectionState === 'connected') {
                    console.log("[Millicast] Connection established after reinitialization");
                    view.webRTCPeer.peer.removeEventListener('connectionstatechange', onConnect);
                  }
                });
              }
            } catch (error) {
              console.error("[Millicast] Connection attempt failed:", error);
              handleStreamState("Error");
            }
          } else if (view.webRTCPeer?.peer?.connectionState === 'connected') {
            console.log("[Millicast] Connection already established, checking tracks");
            const tracks = view.webRTCPeer.peer.getTransceivers()
              .map(transceiver => transceiver.receiver.track)
              .filter(track => track !== null);
              
            const hasVideoTrack = tracks.some(track => track?.kind === 'video');
            const hasAudioTrack = tracks.some(track => track?.kind === 'audio');
            
            if (hasVideoTrack && hasAudioTrack) {
              console.log("[Millicast] All tracks available, setting to Playing");
              handleStreamState("Playing");
            }
          }
          wasStreamPaused = false;
        }
        break;

      case "inactive":
        console.log("[Millicast] Stream inactive, initiating reconnection");
        await handleStreamStop();
        handleStreamState("Paused");
        wasStreamPaused = true;
        break;

      case "stopped":
        console.log("[Millicast] Stream stopped, cleaning up");
        await handleStreamStop();
        handleStreamState("Stopped");
        break;

      default:
        break;
    }
  });

  // Monitor FPS and manage state accordingly.
  const monitorFPS = async (peer: RTCPeerConnection) => {
    try {
      if (!peer || peer.connectionState !== "connected" || isReconnecting) {
        console.log("Skipping FPS monitoring:", {
          peerExists: !!peer,
          connectionState: peer?.connectionState,
          isReconnecting,
        });
        return;
      }

      const stats = await peer.getStats();
      let videoStats: any = null;
      for (const report of Array.from(stats.values())) {
        if (report.type === "inbound-rtp" && report.kind === "video") {
          videoStats = report;
          break;
        }
      }

      if (videoStats) {
        const currentFrames = videoStats.framesDecoded;
        const currentTime = videoStats.timestamp;
        const timeDelta = (currentTime - lastTime) / 1000;
        const framesDelta = currentFrames - lastFrames;
        const currentFPS = framesDelta / timeDelta;

        // Update FPS history for smoothing.
        fpsHistory.push(currentFPS);
        if (fpsHistory.length > FPS_HISTORY_SIZE) {
          fpsHistory.shift();
        }
        const smoothedFPS =
          fpsHistory.reduce((a, b) => a + b, 0) / fpsHistory.length;

        console.log("Current FPS:", {
          raw: currentFPS.toFixed(2),
          smoothed: smoothedFPS.toFixed(2),
          framesDelta,
          timeDelta: timeDelta.toFixed(3),
        });

        if (smoothedFPS < 0.1 && currentStreamState !== "Stopped") {
          if (!lastFpsZeroTime) {
            lastFpsZeroTime = Date.now();
            console.log("Starting zero FPS timer");
          } else if (Date.now() - lastFpsZeroTime >= FPS_ZERO_THRESHOLD) {
            if (!wasStreamPaused) {
              console.log("FPS has been zero for too long, setting stream to Paused");
              handleStreamState("Paused");
              wasStreamPaused = true;
            }
          }
        } else if (lastFpsZeroTime) {
          lastFpsZeroTime = null;
          console.log("Resetting zero FPS timer");
          if (wasStreamPaused) {
            console.log("FPS restored, setting stream to Playing");
            handleStreamState("Playing");
            wasStreamPaused = false;
          }
        }

        // Update tracking variables.
        lastFrames = currentFrames;
        lastTime = currentTime;
      }
    } catch (error) {
      console.error("Error in monitorFPS:", error);
    }
  };

  // Helper to add a MediaStream to the video element.
  function addStreamToYourVideoTag(stream: MediaStream) {
    console.log("Received stream:", stream);
    if (stream.getAudioTracks().length === 0) {
      console.error("No audio tracks in the stream");
      return;
    }
    // Ensure the first audio track is enabled.
    const audioTrack = stream.getAudioTracks()[0];
    console.log("Audio Track:", audioTrack);
    audioTrack.enabled = true;

    if (videoEl.current) {
      videoEl.current.srcObject = stream;
      videoEl.current.autoplay = true;
      videoEl.current.controls = false;
      console.log("Video element updated with new stream");
    }
  }

  // Options for connection
  type BroadcastEvent = "active" | "inactive" | "stopped";
  const events: BroadcastEvent[] = ["active", "inactive", "stopped"];
  const options = {
    events,
    disableVideo: false,
    disableAudio: false,
    bandwidth: 0, // Unlimited bandwidth
    metadata: true,
    dtx: true,
    absCaptureTime: true,
  };

  try {
    console.log("[Millicast] Attempting initial connection");
    await view.connect(options);
    const peer = view.webRTCPeer?.peer || null;
    console.log("[Millicast] Initial connection result:", {
      hasPeer: !!peer,
      peerState: peer?.connectionState,
      signalingState: peer?.signalingState
    });

    if (peer) {
      peer.addEventListener('connectionstatechange', () => {
        const state = peer.connectionState;
        console.log('[Millicast] Connection state changed:', {
          state,
          iceState: peer.iceConnectionState,
          signalingState: peer.signalingState
        });
        
        switch (state) {
          case 'connected':
            // Only set to Playing if we have both a valid source and tracks
            const tracks = peer.getTransceivers()
              .map(transceiver => transceiver.receiver.track)
              .filter(track => track !== null);
              
            const hasVideoTrack = tracks.some(track => track?.kind === 'video');
            const hasAudioTrack = tracks.some(track => track?.kind === 'audio');
            
            console.log('[Millicast] Checking tracks for Playing state:', {
              hasVideoTrack,
              hasAudioTrack,
              hasSource: !!currentSourceId
            });
            
            if (hasVideoTrack && hasAudioTrack && currentSourceId) {
              console.log("[Millicast] All conditions met, setting to Playing");
              handleStreamState("Playing");
            } else {
              console.log("[Millicast] Waiting for missing components");
            }
            break;
            
          case 'failed':
            console.log("[Millicast] Connection failed");
            handleStreamState("Error");
            break;
            
          case 'disconnected':
            console.log("[Millicast] Connection disconnected, attempting reconnect");
            if (!isReconnecting) {
              isReconnecting = true;
              view.reconnect().catch(error => {
                console.error("[Millicast] Reconnection failed:", error);
                handleStreamState("Error");
              });
            }
            break;
        }
      });

      // Set up FPS monitoring with logging
      const fpsMonitorInterval = setInterval(() => {
        if (peer.connectionState === "connected") {
          monitorFPS(peer).catch(err => {
            console.error("[Millicast] FPS monitoring error:", err);
          });
        }
      }, 1000);

      peer.addEventListener("connectionstatechange", () => {
        if (peer.connectionState === "closed" || peer.connectionState === "failed") {
          console.log("[Millicast] Clearing FPS monitor due to connection state:", peer.connectionState);
          clearInterval(fpsMonitorInterval);
        }
      });
    }

    return { view, peer };
  } catch (error) {
    console.error("[Millicast] Initial connection failed:", error);
    handleStreamState("Error");
    return { view, peer: null };
  }
};
