import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { Director, View } from '@millicast/sdk';
import { useGetCurrentUser, useWatermark } from "../../../../hooks/useServerQueries";
import { IDolbyConfig } from 'types/';
import { StreamContext, SocketContext } from 'context/';
import {millicast} from "../../../../utils/milicast";
import AudioLevelMeter from 'ui/components-library/AudioLevelMeter';

interface StreamElementProps {
  onAudioOffsetChange?: (offset: number) => void;
  showAudioOffset?: boolean;
  useHostedPlayer?: boolean;
}

export default function StreamElement({ onAudioOffsetChange, showAudioOffset = true, useHostedPlayer = false }: StreamElementProps) {
  console.log('[StreamElement] Initializing with props:', { showAudioOffset, useHostedPlayer });

  const { 
    streamOutputGain, setStreamOutputLevel, setStreamOutputGain,
    dolbyStreamingConfig, streamState, setStreamState, roomData, 
    muteCallOutput, muteStreamOutput, audioElement, speakerDeviceSelected, 
    setAudioElement, setWebRTCPeer
  } = useContext(StreamContext);
  const {socketData} = useContext(SocketContext); 
  const videoEl = useRef<any>(null);
  const millicastViewRef = useRef<any>(null);
  const [audioDelay, setAudioDelay] = useState<number>(0);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const sessionId = localStorage.getItem('sessionId');
  const { mutateAsync: Watermark } = useWatermark(sessionId);
  const { mutateAsync: CurrentUser } = useGetCurrentUser();
  const [watermarkConfig, setWatermarkConfig] = useState<any>();
  const [currentUserData, setCurrentUserData] = useState<any>();
  const audioContextRef = useRef<AudioContext | null>(null);
  const delayNodeRef = useRef<DelayNode | null>(null);
  const sourceNodeRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const gainNodeRef = useRef<GainNode | null>(null);


  // Initialize audio processing
  const initAudioProcessing = useCallback(async () => {
    if (videoEl.current?.srcObject) {
      try {
        // Cleanup any existing audio processing
        await cleanup();
        
        // Create new audio context
        audioContextRef.current = new AudioContext();
        if (audioContextRef.current.state === 'suspended') {
          await audioContextRef.current.resume();
        }
  
        const stream = videoEl.current.srcObject as MediaStream;
        sourceNodeRef.current = audioContextRef.current.createMediaStreamSource(stream);
  
        // Create and store the gain node
        const gainNode = audioContextRef.current.createGain();
        gainNode.gain.value = streamOutputGain;
        gainNodeRef.current = gainNode;
  
        // Create delay node and set its delay
        delayNodeRef.current = audioContextRef.current.createDelay(5.0);
        delayNodeRef.current.delayTime.value = audioDelay / 1000;
  
        // Create destination node
        const destinationNode = audioContextRef.current.createMediaStreamDestination();
  
        // Connect nodes (with or without delay)
        sourceNodeRef.current.connect(gainNode);
        if (audioDelay > 0) {
          gainNode.connect(delayNodeRef.current);
          delayNodeRef.current.connect(destinationNode);
        } else {
          gainNode.connect(destinationNode);
        }
  
        // Always mute the original video element
        if (videoEl.current) {
          videoEl.current.muted = true;
        }
  
        // Create and set up the processed audio element
        const processedAudioEl = new Audio();
        processedAudioEl.srcObject = destinationNode.stream;
        processedAudioEl.autoplay = true;
        processedAudioEl.muted = muteStreamOutput;
  
        if (speakerDeviceSelected) {
          await processedAudioEl.setSinkId(speakerDeviceSelected);
        }
  
        // Store the processed audio element for later cleanup
        setAudioElement(processedAudioEl);
        setIsVideoReady(true);
      } catch (error) {
        console.error('Failed to initialize audio processing:', error);
        setIsVideoReady(false);
        await cleanup();
      }
    }
  }, [audioDelay, speakerDeviceSelected, muteStreamOutput]);
// Cleanup function
const cleanup = async () => {
  // Stop the processed audio element if it exists
  if (audioElement) {
    audioElement.pause();
    audioElement.srcObject = null;
    setAudioElement(null);
  }
  
  // Disconnect audio nodes
  if (sourceNodeRef.current) {
    sourceNodeRef.current.disconnect();
    sourceNodeRef.current = null;
  }
  if (delayNodeRef.current) {
    delayNodeRef.current.disconnect();
    delayNodeRef.current = null;
  }
  if (gainNodeRef.current) {
    gainNodeRef.current.disconnect();
    gainNodeRef.current = null;
  }
  
  // Close the AudioContext
  if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
    await audioContextRef.current.close();
    audioContextRef.current = null;
  }
  
  setIsVideoReady(false);
};

  // Initialize audio processing when stream is ready
  useEffect(() => {
    let mounted = true;

    const initAudio = async () => {
      if (videoEl.current?.srcObject && mounted) {
        await initAudioProcessing();
      }
    };

    initAudio();

    return () => {
      mounted = false;
      // Cleanup - only close if it's not already closed
      if (sourceNodeRef.current) {
        sourceNodeRef.current.disconnect();
        sourceNodeRef.current = null;
      }
      if (delayNodeRef.current) {
        delayNodeRef.current.disconnect();
        delayNodeRef.current = null;
      }
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close().catch(err => {
          console.error('Error closing AudioContext:', err);
        });
      }
      audioContextRef.current = null;
      setIsVideoReady(false);
    };
  }, [videoEl.current?.srcObject, initAudioProcessing]);

  // Handle audio delay change
  const handleAudioDelayChange = useCallback((value: number) => {
    // Clamp the value between 0 and 5000
    const clampedValue = Math.min(Math.max(value, 0), 5000);
    
    // Update local state
    setAudioDelay(clampedValue);
    
    // Notify parent component immediately
    onAudioOffsetChange?.(clampedValue);
    
    // Update audio processing
    if (delayNodeRef.current && audioContextRef.current?.state === 'running') {
      const delaySeconds = clampedValue / 1000;
      delayNodeRef.current.delayTime.setValueAtTime(delaySeconds, audioContextRef.current.currentTime);
    }
  }, [onAudioOffsetChange]);

  // Handle input change with validation
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Just update the input value without applying the delay
    setInputValue(value);
  };

  // Handle input blur to apply value or restore last valid value
  const handleInputBlur = () => {
    const value = inputValue.trim();
    if (value === '' || isNaN(parseInt(value))) {
        // Default to 0 when empty or invalid
        setInputValue('0');
        handleAudioDelayChange(0);
    } else {
        // Apply the new value
        handleAudioDelayChange(parseInt(value));
    }
  };

  // Handle key press for Enter
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
        e.currentTarget.blur(); // This will trigger handleInputBlur
    }
  };

  // Add effect to ensure audio delay changes are reflected
  useEffect(() => {
    console.log('Audio delay updated:', audioDelay);
    onAudioOffsetChange?.(audioDelay);
  }, [audioDelay, onAudioOffsetChange]);

  // Add this effect to reinitialize audio when the stream changes
  useEffect(() => {
    if (videoEl.current?.srcObject) {
      const initAudio = async () => {
        try {
          // Clean up existing audio context
          if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
            await audioContextRef.current.close();
          }
          audioContextRef.current = null;
          
          // Reinitialize audio processing
          await initAudioProcessing();
        } catch (error) {
          console.error('Error reinitializing audio:', error);
          setIsVideoReady(false);
        }
      };

      initAudio();
    }
  }, [dolbyStreamingConfig]);

  // Remove the conflicting mute effect
  useEffect(() => {
    if (videoEl.current && audioContextRef.current) {
        // Always keep the video element muted since we're using Web Audio API
        videoEl.current.muted = true;
        
        // Handle muting through the audio context
        if (muteStreamOutput) {
            audioContextRef.current.suspend().catch(err => {
                console.error('Error suspending AudioContext:', err);
            });
        } else {
            audioContextRef.current.resume().catch(err => {
                console.error('Error resuming AudioContext:', err);
            });
        }
    }
    
    // Also update the processed audio element if it exists
    if (audioElement) {
        audioElement.muted = muteStreamOutput;
    }
}, [muteStreamOutput, audioElement]);

  const watermark = async () => {
      if (sessionId) {
        const watermarkConfig = await Watermark(sessionId)
        if (watermarkConfig) {
          const currentUserData = await CurrentUser();
          setWatermarkConfig(watermarkConfig)
          setCurrentUserData(currentUserData)
        }
      }
    }
  // const VolumeMeter = ({ volume }: { volume: number }) => {
  //   return <div style={{ position: 'absolute', width: `${volume}%`, backgroundColor: 'green', height: '20px' }} />;
  // };
  useEffect(() => {
    try{
    watermark()
    }
    catch(e){
      console.log(e)
    }
    console.log('watermark', watermarkConfig)

  }, []);

 useEffect(() => {
  console.log("ABCDE", socketData)
  if (!socketData) return;

 const handleMessage = (event: any) => {
        
        const eventData = JSON.parse(event.data);
        console.log("#EVENTWATERMARK", eventData)
        switch (eventData.type) {
          case 'PLAYBACK_WATERMARK_SETTINGS_CHANGED': {
            try{
              watermark()
            }
            catch(e){
              console.log(e)
            }
            break;
        }
      }
    }
  
  socketData.addEventListener('message', handleMessage);
  return () => {
    socketData.removeEventListener('message', handleMessage);
  };
}, [socketData]);

  // Modify the Millicast initialization part
  useEffect(() => {
    let isComponentMounted = true;
    console.log('[StreamElement] Starting Millicast initialization with config:', dolbyStreamingConfig);
    console.log('[StreamElement] Current stream state:', streamState);

    // Only initialize if we have valid config
    if (!dolbyStreamingConfig?.streamName || !dolbyStreamingConfig?.accountId) {
      console.log('[StreamElement] Missing required config, skipping initialization');
      return;
    }

    millicast(dolbyStreamingConfig, videoEl, setStreamState)
      .then(async ({ view, peer }) => {
        if (!isComponentMounted) {
          console.log('[StreamElement] Component unmounted during initialization, cleaning up');
          if (view) {
            view.stop();
            if (view.webRTCPeer?.peer) {
              view.webRTCPeer.peer.close();
            }
          }
          if (peer) {
            peer.close();
          }
          return;
        }

        console.log('[StreamElement] Millicast initialized successfully:', {
          hasView: !!view,
          hasPeer: !!peer,
          peerState: peer?.connectionState,
          streamState
        });

        millicastViewRef.current = view;
        
        if (peer) {
          setWebRTCPeer(peer);
          
          peer.addEventListener('connectionstatechange', () => {
            console.log('[StreamElement] WebRTC connection state changed:', {
              state: peer.connectionState,
              iceState: peer.iceConnectionState,
              signalingState: peer.signalingState,
              currentStreamState: streamState
            });
            
            if (peer.connectionState === 'connected') {
              const tracks = peer.getReceivers().map(receiver => receiver.track);
              const audioTracks = tracks.filter(t => t?.kind === 'audio');
              const videoTracks = tracks.filter(t => t?.kind === 'video');
              
              console.log('[StreamElement] Connected with tracks:', {
                audioTracks: audioTracks.length,
                videoTracks: videoTracks.length,
                audioEnabled: audioTracks.some(t => t?.enabled),
                videoEnabled: videoTracks.some(t => t?.enabled)
              });
              console.log('[StreamElement] Audio tracks:', audioTracks);
              console.log('[StreamElement] Video tracks:', videoTracks);
              // Only set to Playing if we have both types of tracks and they're enabled
              if (audioTracks.length > 0 && videoTracks.length > 0 && 
                  audioTracks.some(t => t?.enabled) && videoTracks.some(t => t?.enabled) && audioTracks.some(t => t?.readyState !== 'ended') && videoTracks.some(t => t?.readyState === 'ended')) {
                console.log('[StreamElement] All tracks available and enabled, setting to Playing', audioTracks , videoTracks);
                setStreamState('Playing');
              } else {
                console.log('[StreamElement] Missing or disabled tracks, waiting');
              }
            }
          });


          peer.addEventListener('track', (event) => {
            console.log('[StreamElement] Received track:', {
              kind: event.track.kind,
              enabled: event.track.enabled,
              readyState: event.track.readyState,
              currentStreamState: streamState
            });
          });
        }

        if (videoEl.current) {
          videoEl.current.muted = true;
        }
      })
      .catch((err) => {
        console.error('[StreamElement] Error initializing Millicast stream:', err);
        setWebRTCPeer(null);
        setStreamState('Error');
      });

    return () => {
      console.log('[StreamElement] Cleaning up Millicast connection');
      isComponentMounted = false;
      if (millicastViewRef.current) {
        millicastViewRef.current.stop();
      }
    };

  }, [dolbyStreamingConfig, streamState]);

  useEffect(()=>{
    if(speakerDeviceSelected && speakerDeviceSelected !== videoEl.current.sinkId){
      videoEl.current.setSinkId(speakerDeviceSelected).catch((err:any) => console.error('Error setting audio output device:', err));
      }
    
  }, [speakerDeviceSelected])

  useEffect(()=>{
    videoEl.current.muted = muteStreamOutput
    //videoEl.current.volume = streamOutputGain
},[muteStreamOutput])

  // Add effect for device selection changes
  useEffect(() => {
    if (audioElement && speakerDeviceSelected) {
        audioElement.setSinkId(speakerDeviceSelected)
            .catch((err: Error) => console.error('Error setting audio output device:', err));
    }
  }, [speakerDeviceSelected, audioElement]);

  return (
    <div className="relative w-full h-full" data-player-container="custom">
      <div className="relative flex flex-col items-center justify-center w-full h-full" style={{ backgroundColor: 'black' }}>
        <video
          ref={videoEl}
          id="videoMain"
          autoPlay
          playsInline
          style={{
            visibility: `${(streamState==='Playing')?'visible':'hidden'}`,
            width: '100%',
            height: '100%'
          }}
        />
        
        {/* Audio Offset Control */}
        {showAudioOffset && (
          <div className="absolute bottom-4 right-4 z-10 bg-black/80 p-3 rounded-lg flex flex-col gap-2 text-white">
            <div className="flex items-center gap-2">
              <label htmlFor="audioDelay" className="text-sm whitespace-nowrap">
                Audio Offset:
              </label>
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="0 ms"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyPress={handleKeyPress}
                className="w-20 px-2 py-1 text-sm bg-gray-700 rounded border border-gray-600 text-white placeholder-gray-400"
              />
              <span className="text-sm">ms</span>
              <button
                onClick={() => {
                  handleAudioDelayChange(0);
                  setInputValue('');
                }}
                className="px-2 py-1 text-xs bg-blue-500 hover:bg-blue-600 rounded"
              >
                Reset
              </button>
            </div>
          </div>
        )}

        {(currentUserData && watermarkConfig) &&
            <div className="absolute top-0 left-0 w-full h-full">
              <p className="text-center textt-xl pt-2">{`${watermarkConfig?.ipOverlay?(currentUserData?.firstName + ' ' + currentUserData?.lastName + ' ' + roomData.ownExternalIp):''}`}</p>
            </div>
        }
      </div>
    </div>
  );
}
